/* 
  This style targets the active item in the bottom navigation list of the sidebar.
  It applies a background color and padding for the active state.
*/
.bottom ul li.active {
  background-color: rgba(219, 180, 214, 0.863);
  border-radius: 50px;
  padding-left: 20px;
  // padding-right: 20px
}

.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  background-color: #f8f3f3;
  color: #333;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  z-index: 10;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar .top {
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #555;
}

.sidebar hr {
  margin: 0;
  border: 1px solid #555;
}

.sidebar .bottom {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px;
}

.sidebar .bottom ul {
  list-style: none;
  padding: 0;
}

.sidebar .bottom ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.sidebar .bottom ul li .icon {
  margin-right: 10px;
}

.sidebar .bottom ul li span {
  font-size: 18px;
}

.toggle-sidebar-btn {
  position: fixed;
  left: 0;
  top: 15px;
  color: #0c0b0b;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1001;
}

@media (min-width: 769px) {
  .toggle-sidebar-btn {
    display: none;
  }
}