// html,
// body {
//   margin: 0;
//   padding: 0;
//   height: 100%;
// }

// #root {
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
// }

// .page-container {
//   flex: 1;
// }

// footer {
//   background-color: #333;
//   color: #fff;
//   text-align: center;
//   padding: 15px;
//   height: 70px;
//   width: 100%;
//   margin-top: auto;
//   /* Push footer to the bottom */
// }
/* Make sure the html and body take the full height */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  
}

main {
  flex-grow: 1;

}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 15px;
  height: 70px;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto;
  /* Pushes footer to the bottom */
}
// html,
// body {
//   margin: 0;
//   padding: 0;
//   height: 100%;
// }

// #root {
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
// }

// .page-container {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   /* Ensures the page container takes up the full available space */
// }

// main {
//   flex-grow: 1;
//   /* Ensures the main section grows to push the footer down */
//   padding-bottom: 70px;
//   /* Ensures content does not overlap with the footer */
// }
// .bullet-point {
//   font-size: 20px;
//   color: #000;
// }
// footer {
//   background-color: #333;
//   color: #fff;
//   text-align: center;
//   padding: 15px;
//   height: 70px;
//   width: 100%;
//   position: relative;
//   bottom: 0;
//   margin-top: auto;
//   /* Pushes footer to the bottom */
// }

.calendar-icon i {
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
}

.table-container {
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f7fc;
  border-radius: 8px;
}

.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
  padding: 0 20px;
}

.book-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  table-layout: fixed;
  border-radius: 8px;
  overflow: hidden;
}

.book-table th,
.book-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.book-table tbody tr {
  height: 50px;
  min-height: 50px;
}

.book-table tbody td {
  padding: 10px;
  vertical-align: middle;
}

.book-table th {
  background-color: #7cb2d0;
  color: white;
}

.book-table td {
  background-color: #fff;
}

.submit-btn {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #45a049;
}

.loading {
  text-align: center;
  font-size: 20px;
  color: #333;
}

.show-more {
  cursor: pointer;
  text-decoration: none;
}

.popup {
  display: none;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup.show {
  display: block;
}

// .popup-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 999;
// }

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-btn {
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  background-color: #4CAF50;
  color: white;
}

.page-btn:focus {
  outline: none;
}

.inline-input {
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
}

.inline-input:focus {
  border-bottom: 1px solid #7cb2d0;
  cursor: text;
}

.inline-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.calendar-icon {
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
}

.date-picker {
  width: 150px;
  padding: 5px;
}

.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &.success {
    background-color: #4caf50;
  }

  &.error {
    background-color: #f44336;
  }

  button {
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {

  .book-table th,
  .book-table td {
    padding: 6px;
    font-size: 12px;
  }

  .heading {
    font-size: 20px;
  }

  .submit-btn {
    padding: 5px 10px;
  }
}

// .book-table th:first-child,
// .book-table td:first-child {
//   width: 100px;
//   max-width: 50px;
// }

.book-table th:nth-child(4),
.book-table td:nth-child(4) {
  width: 60px;
  max-width: 60px;
}

@media (max-width: 480px) {

  .book-table th,
  .book-table td {
    padding: 6px;
    font-size: 12px;
  }

  .heading {
    font-size: 20px;
  }

  .submit-btn {
    padding: 5px 10px;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative;
  /* Ensure the close button can be positioned relative to this */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  /* Adjust as needed */
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.close-popup:hover {
  color: red;
  /* Change color on hover */
}
/* Apply this to your table */
.book-table {
  width: 100%;
  table-layout: fixed;
  /* Ensures all columns take equal width */
}

/* Apply a fixed width for the 'Book Title' column */
.book-table th:nth-child(4),
/* Target the 'Book Title' header */
.book-table td:nth-child(4) {
  /* Target the 'Book Title' cells */
  width: auto;
  /* Or you can specify a percentage like 'width: 15%' */
}

/* Optional: Ensure consistency for other columns */
.book-table th,
.book-table td {
  text-align: left;
  padding: 8px;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-body {
  text-align: center;
}

.popup-heading {
  font-size: 24px;
  color: #324e6a;
  margin-bottom: 20px;
}

.book-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.book-details {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
}

.book-title,
.book-quantity,
.book-price {
  margin: 5px 0;
}

.book-title strong,
.book-quantity strong,
.book-price strong {
  color: #2c3e50;
}

// .confirmation-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .confirmation-box {
//   background-color: white;
//   padding: 20px;
//   border-radius: 5px;
//   text-align: center;
// }

// .confirm-button {
//   background-color: green;
//   color: white;
//   padding: 10px 20px;
//   margin-right: 10px;
// }

// .cancel-button {
//   background-color: red;
//   color: white;
//   padding: 10px 20px;
// }


.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it appears on top of other elements */
}

.confirmation-box {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  /* Rounded corners */
  text-align: center;
  width: 300px;
  /* Fixed width for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Soft shadow */
}

.confirmation-box p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  /* Spacing between text and buttons */
  color: #333;
}

.button-container {
  display: flex;
  justify-content: space-around;
  /* Equal space between buttons */
}

.confirm-button,
.cancel-button {
  background-color: #28a745;
  /* Green by default for OK */
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  /* Rounded corners for buttons */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth hover effect */
  width: 100px;
  /* Fixed width for buttons */
}

.confirm-button {
  background-color: green;
  /* OK button color */
}

.confirm-button:hover {
  background-color: #218838;
  /* Darker green on hover */
  transform: scale(1.05);
  /* Slight zoom effect on hover */
}

.cancel-button {
  background-color: #dc3545;
  /* Red for Cancel */
}

.cancel-button:hover {
  background-color: #c82333;
  /* Darker red on hover */
  transform: scale(1.05);
  /* Slight zoom effect on hover */
}

.cancel-button {
  margin-left: 10px;
  /* Space between the buttons */
}
.payment-status {
  font-weight: bold;
}

.payment-status.confirmed {
  color: green;
}

.payment-status.not-confirmed {
  color: red;
}