/* Widget container styles */
.widget {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* Widget type specific styles */
.widget.customer {
  background-color: #ff000033;
  /* Crimson */
}

.widget.order {
  background-color: #daa52033;
  /* Goldenrod */
}

.widget.earnings {
  background-color: #00800033;
  /* Green */
}

.widget.balance {
  background-color: #80008033;
  /* Purple */
}

/* Widget content styles */
.widget .left,
.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.widget .title {
  font-weight: bold;
  font-size: 14px;
  color: #616161;
}

.widget .counter {
  font-size: 28px;
  font-weight: 300;
}

.widget .link {
  font-size: 12px;
  border-bottom: 1px solid gray;
  cursor: pointer;
}

/* Icon styles */
.widget .icon {
  font-size: 18px;
  padding: 5px;
  align-self: flex-end;
  cursor: pointer;
}

/* Percentage styles */
.widget .percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.widget .percentage.positive {
  color: green;
}

.widget .percentage.negative {
  color: red;
}

/* Hover effect */
.widget:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .widget {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .widget .left,
  .widget .right {
    width: 100%;
    align-items: flex-start;
  }

  .widget .right {
    margin-top: 10px;
  }
}