.book-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.book-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* General page styling */
.book-page {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7fb;
  // padding: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Heading */
h2 {
  text-align: center;
  color: #4a6e9f;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 600;
}

/* Input field styling */
.book-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

/* Button styling */
.book-button {
  background-color: #4a6e9f;
  color: #333;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #3b5678;
}

/* Book container grid */
.book-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 30px;
  // margin-top: 20px;
}

/* Book card styling */
.book-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.book-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Book details styling */
.book-details {
  text-align: center;
}

.book-title {
  font-size: 16px;
  font-weight: 500;
  color: #4a6e9f;
  margin-bottom: 10px;
  cursor: pointer;
}

.book-title:hover {
  text-decoration: underline;
}

// .book-image {
//   margin-bottom: 15px;
// }

// .book-image img {
//   width: 100%;
//   height: auto;
//   border-radius: 4px;
// }

.book-details p {
  color: #666;
  font-size: 14px;
  margin: 8px 0;
}

/* Book update form styling */
.book-update-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-update-input {
  padding: 10px;
  width: 200px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

/* Popup container styling */
.book-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content box styling */
.book-popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  position: relative;
  /* To position close button inside */
}

/* Popup header styling */
.book-popup-header {
  font-size: 24px;
  font-weight: 600;
  color: #4a6e9f;
  margin-bottom: 20px;
}

/* Error message styling */
.book-error {
  color: red;
  margin-bottom: 10px;
}

/* Form group styling */
.book-form-group {
  margin-bottom: 15px;
}

.book-form-group label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: #4a6e9f;
}

/* Popup button styling */
.book-form-buttons {
  display: flex;
  justify-content: space-between;
}

.book-popup-content button {
  width: 25%;
}

/* Close button (cross) styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: red;
  /* Red color for the cross */
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1050;
  /* Ensure it's above other elements */
}

.close-button:hover {
  color: #ff0000;
  /* Change color on hover */
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
  .book-container {
    grid-template-columns: 1fr 1fr;
  }
}