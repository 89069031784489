.query-heading {
    text-align: center;
    color: #333;
    font-family: Georgia, serif;
    font-size: 28px;
    font-weight: bold;


}

.contact-container {
    display: flex;
    justify-content: center;
    /* Center the content horizontally */
    padding: 20px;
    width: 1000px;
    /* Fixed width */
    background-color: #ffffff;
    /* White background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow */
    border-radius: 8px;
    /* Rounded corners */
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
    margin: 0 auto;
    /* Center container horizontally */
    margin-top: 150px;
}

.contact-info {
    width: 40%;
    margin-right: 20px;
    /* Space between info and form */
}

.contact-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-info p {
    margin: 5px 0;
}

.social-icons {
    margin-top: 20px;
}

.social-icons a {
    margin-right: 10px;
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.contact-form {
    width: 55%;
}

.contact-form p {
    margin-bottom: 20px;
    font-size: 18px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form button {
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #d4af37;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #c39b26;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        width: 90%;
    }

    .contact-info,
    .contact-form {
        width: 100%;
        margin-right: 0;
        /* Remove margin on small screens */
    }

    .contact-info {
        margin-bottom: 20px;
    }
}