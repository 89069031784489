.pagination-container {
    text-align: center;
    margin-top: 20px;
    /* Adjust the margin as needed */
}

.faded-line {
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin-top: 10px;
    /* Adjust the margin as needed */
    position: relative;
}

.faded-line::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    /* Adjust the height of the gradient */
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.user-list-title {
    text-align: center;
    margin-bottom: 20px;

    color: #333;
    /* Change the color as needed */
}

.user-container {
    display: flex;
    position: relative;
}

.sidebar-container {
    flex: 0 0 250px;
    background: #f0f0f0;
    padding: 20px;
}

.main-content {
    margin: 20px;
    flex: 1;
    overflow-x: auto;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.loading-indicator {
    text-align: center;
}

.blink {
    animation: blink 1s infinite;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.user-table th,
.user-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

.user-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    color: #212529;
}

.user-row:hover {
    background-color: #f2f2f2;
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background: white;
    color: #007bff;
}

.pagination button.active {
    background: #007bff;
    color: white;
}

/* Button styles */
.button {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

/* Keyframe animation for blink effect */
@keyframes blink {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    width: 250px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Update boxShadow to camelCase */
    z-index: 1;
    /* Update zIndex to camelCase */
    transition: width 0.3s ease;
}

.user-container {
    flex: 1;
    padding: 20px;
    margin-left: 250px;
    transition: margin-left 0.3s ease;
    overflow-y: auto;
    /* Enable vertical scrolling */
}


@media (max-width: 768px) {
    .sidebar {
        width: 250px;
        transition: width 0.3s ease;
    }

    .user-container {
        margin-left: 0;
    }
}