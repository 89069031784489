/* External CSS styles for Recipes component */

.recipe-title {
  text-align: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Update boxShadow to camelCase */
  z-index: 1;
  /* Update zIndex to camelCase */
  transition: width 0.3s ease;
}

.content-after-sidebar {
  flex: 1;
  padding: 20px;
  margin-left: 250px;
  transition: margin-left 0.3s ease;
  overflow-y: auto;
  /* Enable vertical scrolling */
}


@media (max-width: 768px) {
  .sidebar {
    width: 250px;
    transition: width 0.3s ease;
  }

  .content-after-sidebar {
    margin-left: 0;
  }
}