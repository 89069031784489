   .service-agreement {
       padding: 20px;
       line-height: 1.6;
       font-family: Arial, sans-serif;
       color: #333;
       margin-left: 10%;
       margin-right: 10%;

   }

   .service-agreement h3,
   .service-agreement h5 {
       color: #0073e6;
       /* text-align: center; */
   }

   .service-agreement h3 {
       font-size: 2em;
       margin-bottom: 0.5em;
   }

   .service-agreement h5 {
       font-size: 1.5em;
       margin-top: 1.5em;
       margin-bottom: 0.5em;
   }

   .service-agreement p {
       margin-bottom: 1em;
   }